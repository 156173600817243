/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

 module.exports = {
  logo: 'bloqhouse.svg',
  logoalt: 'bloqhouse-alt.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_bloqhouse.scss',
  name: 'Bloqhouse',
  clientName: 'bloqhouse',
  phone: '+31202101225',
  email: 'test@bloqhouse.com',
  website: 'https://bloqhouse.com',
  glossary: true,
  // langFileName: 'nlc.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: true,
  chartColors: {
    greys: {
      color1: '#b1b3bd',
      color2: '#f7f7f7',
    },
    projectColors: {
      color1: '#045193',
      color2: '#4aa6f5',
      color3: '#083f6f',
      color4: '#307ec1',
    },
  },
  language: 'nl',
  showAnimations: true,
  showAssetDescription: false,
};
